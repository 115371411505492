/**
 * App embedded-script or custom script
 * App is expected to expose method in window which the api-loader will call once the element is created
 * Specify the path to method as string in `window-api-path` attribute of the custom element
 * For example `window-api-path=platformCustomElementApi.initWidget`
 * Note: VeloApi will not be available in editor
 * Note: Will be executed in iFrame in editor
 */

import type { VeloApi } from './transport';
import type { RequiredAttributes } from './attributes';

// @ts-expect-error adding new property to window
window.platformCustomElementApi = {
  initWidget: (elem: HTMLElement, api: VeloApi, attrs: RequiredAttributes) => {
    elem.setAttribute('style', 'display: block;');
    elem.innerHTML = '<div>Platform Element</div>';
    console.log('initWidget', elem, api, attrs);
  }
};